import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import urlData from '../CBMS/json/url.json';
import { CBMSAPI } from './api/apiCore';

//로그인 정보
import { getUserFromSession } from '../helpers/api/apiCore';

const TOKEN =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

export function configureFakeBackend() {
    let users = [
        {
            id: 1,
            username: 'test',
            password: 'test',
            firstName: 'Test',
            lastName: 'User',
            role: 'Admin',
            token: TOKEN,
        },
    ];

    mock.onPost('/login/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                const data = { Id: params.username, Pw: params.password };

                CBMSAPI.post(urlData['url'] + urlData['Login'], JSON.stringify(data), {
                    headers: {
                        'Content-Type': `application/json`,
                    },
                })
                    .then((response) => {
                        let user = response.data.response;
                        let responseJson = {
                            id: user.id,
                            name: user.name,
                            role: user.role,
                            email: user.email,
                            organization: user.organization,
                            address: user.address,
                            itu: user.itu,
                            tel: user.tel,
                            fax: user.fax,
                            category: user.category,
                            token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
                        };
                        resolve([200, responseJson]);
                    })
                    .catch((error) => {
                        resolve([401, { message: 'The user information is incorrect' }]);
                    });
            }, 1000);
        });
    });

    mock.onPost('/logout/').reply(function (config) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([200, 'You have successfully logged out!']);
            });
        });
    });

    mock.onPost('/register/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                const data = {
                    Id: params.id,
                    Pw: params.password,
                    Name: params.fullname,
                    Email: params.email,
                    Category: params.category,
                    Organization: params.organization,
                    Address: '-',
                    Itu: '-',
                    Tel: '-',
                    Fax: '-',
                };

                CBMSAPI.post(urlData['url'] + urlData['JOIN'], JSON.stringify(data), {
                    headers: {
                        'Content-Type': `application/json`,
                    },
                })
                    .then((response) => {
                        if (response.data.code === 201) {
                            window.location = '/account/register-success';
                        } else if (response.data.code === -101) {
                            alert('Id already exists.');
                            resolve([401, { message: 'The user information is incorrect' }]);
                        } else if (response.data.code === -103) {
                            alert('Invalid email information.');
                            resolve([401, { message: 'The user information is incorrect' }]);
                        } else if (response.data.code === -201) {
                            alert('Registration failed. Contact your administrator.');
                            resolve([401, { message: 'The user information is incorrect' }]);
                        }
                    })
                    .catch((error) => {
                        resolve([401, { message: 'The user information is incorrect' }]);
                    });
            }, 1000);
        });
    });

    mock.onPost('/forget-password/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);

                // find if any user matches login credentials
                let filteredUsers = users.filter((user) => {
                    return user.username === params.username;
                });

                if (filteredUsers.length) {
                    // if login details are valid return user details and fake jwt token
                    let responseJson = {
                        message: "We've sent you a link to reset password to your registered email.",
                    };
                    resolve([200, responseJson]);
                } else {
                    // else return error
                    resolve([
                        401,
                        {
                            message: 'Sorry, we could not find any registered user with entered username',
                        },
                    ]);
                }
            }, 1000);
        });
    });

    mock.onPost('/personal-info/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                const loggedInUser = getUserFromSession();
                let params = JSON.parse(config.data);
                const data = {
                    Writer: loggedInUser.id,
                    TargetId: loggedInUser.id,
                    Organization: params.organization,
                    Name: params.fullname,
                    Email: params.email,
                    Category: params.category,
                };

                CBMSAPI.put(urlData['url'] + urlData['Member-Info'], JSON.stringify(data), {
                    headers: {
                        'Content-Type': `application/json`,
                    },
                })
                    .then((response) => {
                        if (response.data.code === 203) {
                            let responseJson = {
                                id: loggedInUser.id,
                                name: params.fullname,
                                role: loggedInUser.role,
                                email: params.email,
                                organization: params.organization,
                                address: loggedInUser.address,
                                itu: loggedInUser.itu,
                                tel: loggedInUser.tel,
                                fax: loggedInUser.fax,
                                category: params.category,
                                token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI',
                            };

                            resolve([200, responseJson]);
                        }
                    })
                    .catch((error) => {
                        resolve([401, { message: 'The user information is incorrect' }]);
                    });
            }, 1000);
        });
    });
}
