import { Navigate } from 'react-router-dom';
import { getUserFromSession } from '../helpers/api/apiCore';

const Root = () => {
    const loggedInUser = getUserFromSession();
    const getRootUrl = () => {
        if (loggedInUser) {
            if (loggedInUser.role === 'Admin') {
                let url = 'member/list';
                return url;
            } else {
                let url = 'project/list';
                return url;
            }
        } else {
            let url = 'account/login';
            return url;
        }
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
