import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const RegisterSuccess = React.lazy(() => import('../pages/account/RegisterSuccess'));
const Confirm = React.lazy(() => import('../pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));
const PersonalInfo = React.lazy(() => import('../pages/account/PersonalInfo'));

const Login2 = React.lazy(() => import('../pages/account2/Login2'));
const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
const Register2 = React.lazy(() => import('../pages/account2/Register2'));
const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('../pages/account2/LockScreen2'));

// project
const ProjectList = React.lazy(() => import('../pages/CBMS/Project/List'));
const ProjectAdd = React.lazy(() => import('../pages/CBMS/Project/Add'));
const ProjectShow = React.lazy(() => import('../pages/CBMS/Project/Show'));
const ProjectModify = React.lazy(() => import('../pages/CBMS/Project/Modify'));

// manager
const ManagerMemberList = React.lazy(() => import('../pages/CBMS/Admin/Member/List'));

// managementPlan
const ManagementPlan = React.lazy(() => import('../pages/CBMS/ManagementPlan'));

// other
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Landing = React.lazy(() => import('../pages/landing'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }

        layoutCls = HorizontalLayout;
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'register-success', element: <LoadComponent component={RegisterSuccess} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={['RHC coordi', 'Admin', 'CBSC', 'IHO Sec']} component={Layout} />,
            children: [
                {
                    path: 'account',
                    children: [{ path: 'personal-info', element: <LoadComponent component={PersonalInfo} /> }],
                },
                {
                    path: 'project',
                    children: [
                        { path: 'list', element: <LoadComponent component={ProjectList} /> },
                        { path: 'add', element: <LoadComponent component={ProjectAdd} /> },
                        { path: 'show', element: <LoadComponent component={ProjectShow} /> },
                        { path: 'modify', element: <LoadComponent component={ProjectModify} /> },
                    ],
                },
                {
                    path: 'member',
                    children: [{ path: 'list', element: <LoadComponent component={ManagerMemberList} /> }],
                },
                {
                    path: 'managementPlan',
                    children: [{ path: 'list', element: <LoadComponent component={ManagementPlan} /> }],
                },
            ],
        },
    ]);
};

export { AllRoutes };
